import type { ComponentChildren, FunctionComponent } from "preact";

import { Label } from "./styles";

export const LabelContainer: FunctionComponent<{
  htmlFor?: string;
  label: string;
  children: ComponentChildren;
}> = ({ htmlFor, label, children }) => (
  <div>
    <Label for={htmlFor}>{label}</Label>
    <div class="mt-1">{children}</div>
  </div>
);
