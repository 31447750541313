import type { ParamsOption } from "openapi-fetch";
import type { FilterKeys, PathsWithMethod } from "openapi-typescript-helpers";
import { FunctionalComponent, VNode } from "preact";
import { useState } from "preact/hooks";

import { ApiCall, QueryOptions } from "./api";
import { Placeholder } from "./placeholder";
import { paths } from "./server";
import {
  Button,
  ButtonBar,
  ButtonLink,
  Checkbox,
  FacetButton,
  Table,
  Tbody,
  Td,
  TextInput,
  Th,
  Thead,
  styled,
} from "./styles";
import { EventFor } from "./event-types";

const Facets = styled.div`flex -space-x-px`;
const FacetContainer = styled.ul`relative`;
const FacetResults = styled.li`absolute z-30 bg-white w-full min-w-max divide-y border border-gray-300`;
const FacetResult = styled.label`h-8 flex items-center px-2 py-1 cursor-pointer hover:bg-gray-100 truncate overflow-ellipsis`;

type Column<T> = {
  name: string;
  accessor: (row: T) => VNode | string | null;
};
type Columns<T> = Column<T>[];

type Button = {
  name: string;
  to?: string;
  disabled?: (row: any) => boolean;
  onClick?: () => void;
};
type Buttons = Button[];

const Count = styled.span`inline-block rounded-full bg-gray-300 text-gray-700 text-xs text-center h-4 w-4 ml-2`;

const Facet: FunctionalComponent<{
  items: string[];
  name: string;
  selected: string[];
  onChange: (values: string[]) => void;
}> = ({ items, selected, name, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const update = (name: string) => (e: EventFor<"input", "onChange">) => {
    const target = e.currentTarget;
    if (target instanceof HTMLInputElement) {
      const values = target.checked
        ? [...selected, name]
        : selected.filter((s) => s !== name);
      onChange(values);
    }
  };

  const onblur = (e: EventFor<"div", "onfocusout">) => {
    const related = e.relatedTarget;
    if (related instanceof Element) {
      const closestDiv = related.closest("div");
      if (e.currentTarget.parentElement !== closestDiv) return;
    }
    setIsOpen(false);
  };

  return (
    <FacetContainer>
      <FacetButton
        onClick={() => setIsOpen(!isOpen)}
        onblur={onblur}
        tabindex="-1"
      >
        {name}
        <span class="chevron ml-2" />
        {selected.length > 0 && <Count>{selected.length}</Count>}
      </FacetButton>
      <FacetResults class={isOpen ? "visible" : "invisible"}>
        {items.map((value: any) => (
          <FacetResult tabindex="-1" onblur={onblur}>
            <Checkbox
              type="checkbox"
              class="mr-2"
              onblur={onblur}
              checked={selected.includes(value)}
              onChange={update(value)}
            />
            {value}
          </FacetResult>
        ))}
      </FacetResults>
    </FacetContainer>
  );
};

function Wrapped<Paths extends { [path: string]: any }>() {
  function DataTable<
    K extends keyof Row,
    P extends PathsWithMethod<Paths, "get">,
    Get = Paths[P] extends { get: infer G } ? G : never,
    Row = Get extends {
      responses: {
        200: {
          content: {
            "application/json": {
              data: { rows: Array<infer U>; facets?: Record<string, any[]> };
            };
          };
        };
      };
    }
      ? U
      : never
  >({
    columns,
    facets = {},
    path,
    query,
    placeholderTitle,
    placeholder,
    buttons,
    rowKey,
    onRemove,
    removeLabel,
    removeDisabled,
    search,
  }: {
    columns: Columns<Row>;
    facets: { [key: string]: { name: string; max?: number } };
    path: P;
    placeholderTitle: string;
    placeholder?: string;
    buttons?: Buttons;
    rowKey: K;
    onRemove?: (row: Row) => void;
    removeLabel?: string;
    removeDisabled?: (row: Row) => boolean;
    search?: boolean;
  } & QueryOptions<{
    query: FilterKeys<ParamsOption<Get>["params"], "query">;
  }>) {
    const [removedRows, setRemovedRows] = useState<string[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [selectedFacets, setSelectedFacets] = useState<{
      [key: string]: string[];
    }>({});

    return (
      // @ts-ignore: figure out how to make this work (hint, it has something to do with Wrapped<Paths>)
      <ApiCall<P, Get, { rows: Row[]; facets?: Records<string, any[]> }>
        path={path}
        sticky
        passthrough
        query={{ ...query, ...selectedFacets, filter: filter }}
      >
        {({ data }) => {
          if (!data) return null;
          const view =
            data?.rows?.length === 0 ? (
              <Placeholder title={placeholderTitle}>{placeholder}</Placeholder>
            ) : (
              <Table>
                <Thead>
                  <tr>
                    {columns.map((column) => (
                      <Th>{column.name}</Th>
                    ))}
                    {onRemove && <Th>Action</Th>}
                  </tr>
                </Thead>
                <Tbody>
                  {data?.rows
                    ?.filter(
                      (row) => !removedRows.includes(row[rowKey] as string)
                    )
                    ?.map((row) => (
                      <tr key={row[rowKey]}>
                        {columns.map((column) => (
                          <Td>{column.accessor(row)}</Td>
                        ))}
                        {onRemove && (
                          <Td>
                            <Button
                              disabled={removeDisabled?.(row)}
                              onClick={() => {
                                onRemove(row);
                                setRemovedRows([
                                  ...removedRows,
                                  row[rowKey] as string,
                                ]);
                              }}
                            >
                              {removeLabel}
                            </Button>
                          </Td>
                        )}
                      </tr>
                    ))}
                </Tbody>
              </Table>
            );

          const facetKeys = Object.keys(facets || {});
          const buttonBar = (buttons?.length || data?.facets || search) && (
            <div class="space-y-6">
              <ButtonBar>
                {search && (
                  <TextInput
                    class="max-w-md"
                    id="search"
                    name="search"
                    type="text"
                    placeholder="Search"
                    autocomplete="off"
                    inputmode="search"
                    pattern=".{3,}"
                    onInput={(e: EventFor<"input", "onInput">) =>
                      setFilter(e.currentTarget.value)
                    }
                  />
                )}
                <div class="flex-grow" />
                {facetKeys.length > 0 && (
                  <Facets>
                    {facetKeys.map((facet) => (
                      <Facet
                        items={data?.facets?.[facet]}
                        selected={selectedFacets[facet] || []}
                        name={facets[facet]?.name || facet}
                        onChange={(values) =>
                          setSelectedFacets((f) => ({
                            ...f,
                            [facet]: facets[facet]?.max
                              ? values.reverse().slice(0, facets[facet].max)
                              : values,
                          }))
                        }
                      />
                    ))}
                  </Facets>
                )}
                {buttons?.map((button) => {
                  if (button.to) {
                    return (
                      <ButtonLink to={button.to}>{button.name}</ButtonLink>
                    );
                  } else {
                    return (
                      <Button onClick={button.onClick}>{button.name}</Button>
                    );
                  }
                })}
              </ButtonBar>
            </div>
          );

          return (
            <div class="space-y-6">
              {buttonBar}
              {view}
            </div>
          );
        }}
      </ApiCall>
    );
  }

  return { DataTable };
}

const { DataTable } = Wrapped<paths>();

export { DataTable };
