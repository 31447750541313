import { Component, ComponentChildren, FunctionComponent } from "preact";

export const Placeholder: FunctionComponent<{
  title: Component | string;
  children?: ComponentChildren;
}> = ({ title, children }) => (
  <div class="bg-gray-100 p-8 text-gray-500">
    <p class="text-2xl text-center text-gray-500">{title}</p>
    <p class="text-center">{children}</p>
  </div>
);
