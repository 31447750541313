import type { ComponentChildren, FunctionComponent } from "preact";

import { AuthState, useAuth } from "./api";
import { Login } from "./login";

export const Authenticated: FunctionComponent<{
  children: ComponentChildren;
}> = ({ children }) => {
  const state = useAuth();

  switch (state) {
    case AuthState.Unknown:
      return (
        <div class="min-h-screen items-center flex text-center justify-center w-full text-lg">
          <span>Loading...</span>
        </div>
      );
    case AuthState.Authenticated:
      return <>{children}</>;
    default:
      return <Login />;
  }
};
