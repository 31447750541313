import type { ComponentChildren, FunctionComponent } from "preact";
import { Link } from "wouter-preact";

import { styled } from "./styles";

const HeaderItem = styled.div`h-full flex items-center`;

const logoIcon = new URL("./icons/logo.svg", import.meta.url).toString();

const Header = () => {
  return (
    <header class="fixed z-10 top-0 w-full">
      <div class="flex h-14 px-6 bg-green-500 justify-between">
        <Link href="/">
          <a class="pl-8 sm:pl-0">
            <HeaderItem class="font-bold text-md">
              <img src={logoIcon} class="w-8 h-8 mr-2" />
              CustomerBridge
            </HeaderItem>
          </a>
        </Link>
        <HeaderItem></HeaderItem>
      </div>
    </header>
  );
};

export const Template: FunctionComponent<{
  children: ComponentChildren;
}> = ({ children }) => {
  return (
    <div class="min-h-screen">
      <Header />
      <div class="pt-14 min-h-screen">{children}</div>
    </div>
  );
};
