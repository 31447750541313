import { ComponentProps, ComponentType, h } from "preact";
import { Link } from "wouter-preact";

function interleave(strings: TemplateStringsArray, rest: any[]) {
  return strings.reduce((acc, str, i) => {
    return acc + str + (rest[i] || "");
  }, "");
}

const styledFunc =
  (Elem: any) =>
  (styles: TemplateStringsArray, ...rest: any) =>
  ({ ...props }: any) =>
    h(Elem, {
      ...props,
      class: `${interleave(styles, rest)}${
        props.class ? " " + props.class : ""
      }`,
    });

export const styled = new Proxy<{
  [Tag in keyof h.JSX.IntrinsicElements]: ReturnType<typeof styledFunc>;
}>({} as any, {
  get: (_target: object, Elem: any) => styledFunc(Elem),
});

export const styledC = (Elem: ComponentType<ComponentProps<any>> | string) =>
  styledFunc(Elem);

export const CenterOnScreen = styled.div`flex min-h-full flex-col justify-center px-6 py-12 lg:px-8`;
export const H2 = styled.h2`mt-10 text-2xl font-bold leading-9 tracking-tight text-gray-900`;
export const StyledLink = styledC(Link)`font-semibold leading-6`;
export const StyledAnchor = styled.a`font-semibold leading-6`;
export const TextInput = styled.input`block w-full border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 disabled:text-gray-500`;
export const Label = styled.label`block text-sm font-medium leading-6 text-gray-900`;
export const Container = styled.div`mt-10 sm:mx-auto sm:w-full sm:max-w-sm`;
export const Th = styled.th`p-2`;
export const Td = styled.td`p-2`;
export const Thead = styled.thead`border text-left bg-gray-100 border-gray-300`;
export const Tbody = styled.tbody`border border-gray-300`;
export const ButtonBar = styled.div`flex space-x-2`;
export const Table = styled.table`w-full`;
export const ErrorMessage = styled.div`w-full text-red-500 bg-red-100 font-bold p-2`;
export const SuccessMessage = styled.div`w-full text-green-700 bg-green-100 p-2`;
const buttonStyle = `border border-green-700 disabled:opacity-50 px-4 py-1 text-black font-medium bg-green-400 shadow-[0_2px_0px_0px_rgba(0,0,0,0.1)]`;
export const Button = styled.button`
  ${buttonStyle}
`;
export const ButtonLink = styledC(Link)`${buttonStyle}`;
export const Form = styled.form`space-y-6`;
export const Checkbox = styled.input`border-gray-300 text-green-600 disabled:opacity-40 shadow-[0_2px_0px_0px_rgba(0,0,0,0.1)] focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50`;
export const Content = styled.div`p-6 max-w-7xl space-y-6`;
export const Red = styled.span`bg-red-300 p-1`;
export const Orange = styled.span`bg-orange-300 p-1`;
export const Green = styled.span`bg-green-300 p-1`;
export const Blue = styled.span`bg-blue-300 p-1`;
export const FacetButton = styled.div`
  flex items-center border border-gray-700 disabled:opacity-50 px-4 py-1 text-black bg-gray-100 shadow-[0_2px_0px_0px_rgba(0,0,0,0.1)] cursor-pointer
`;
