import {
  ComponentChildren,
  ComponentProps,
  ComponentType,
  FunctionalComponent,
  createContext,
  h,
} from "preact";
import { useContext } from "preact/hooks";

export const WriteAccessContext = createContext<boolean>(false);

export function requiresWriteAccess<T extends ComponentType<any>>(
  Component: T
) {
  return (props: ComponentProps<T>) => {
    const writeAccess = useContext(WriteAccessContext);
    return writeAccess ? h(Component, props) : null;
  };
}

export const RequiresMutation: FunctionalComponent<{
  children: ComponentChildren;
}> = ({ children }) => {
  const writeAccess = useContext(WriteAccessContext);
  return writeAccess ? <>{children}</> : null;
};
