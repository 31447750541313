import { useState } from "preact/hooks";

import { useMutation } from "./api";
import { LabelContainer } from "./label-container";
import {
  Button,
  ButtonBar,
  Checkbox,
  Content,
  ErrorMessage,
  Form,
  SuccessMessage,
  TextInput,
} from "./styles";

export const AddMember = () => {
  const [email, setEmail] = useState("test@example.org");
  const [admin, setAdmin] = useState(false);
  const [{ data, error }, addMember] = useMutation("/add-member");

  const onSubmit = (event: Event) => {
    event.preventDefault();
    addMember({ email, admin });
  };

  const onCopy = (event: Event) => {
    event.preventDefault();
    const pass = document.getElementById("password") as HTMLInputElement | null;
    if (pass) {
      pass.select();
      pass.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(pass.value);
    }
  };

  return (
    <Content>
      {data?.password ? (
        <Form>
          <LabelContainer htmlFor="email" label="E-mail">
            <TextInput
              id="email"
              name="email"
              type="text"
              value={data.email}
              readonly
            />
          </LabelContainer>
          <SuccessMessage>
            Successfully added member.{" "}
            <b>
              <u>The password will only be shown once.</u> Please copy it to a
              safe place.
            </b>
          </SuccessMessage>
          <LabelContainer htmlFor="password" label="Password">
            <TextInput
              id="password"
              name="password"
              type="text"
              value={data.password}
              readonly
            />
          </LabelContainer>
          <ButtonBar>
            <Button onClick={onCopy}>Copy Password</Button>
          </ButtonBar>
        </Form>
      ) : (
        <Form onSubmit={onSubmit}>
          <LabelContainer htmlFor="email" label="E-mail">
            <TextInput
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              value={email}
              onInput={(event: any) => setEmail(event.currentTarget.value)}
              required
            />
          </LabelContainer>
          <LabelContainer htmlFor="admin" label="Admin">
            <Checkbox
              type="checkbox"
              id="admin"
              name="admin"
              value="admin"
              checked={admin}
              onChange={(event: any) => {
                const { checked } = event.currentTarget;
                setAdmin(checked);
              }}
            />
          </LabelContainer>
          <ButtonBar>
            <Button type="submit">Add Member</Button>
          </ButtonBar>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      )}
    </Content>
  );
};
