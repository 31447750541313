import { FunctionComponent } from "preact";

import { ApiCall, api, useAccessToken, useMutation } from "./api";
import { EventFor } from "./event-types";
import { LabelContainer } from "./label-container";
import {
  Button,
  ButtonBar,
  Content,
  ErrorMessage,
  Form,
  StyledAnchor,
  SuccessMessage,
  TextInput,
} from "./styles";
import { useState } from "preact/hooks";

export const Account: FunctionComponent = () => {
  const accessToken = useAccessToken();
  const [otpResponse, enable] = useMutation("/enable-2fa");
  const [changePasswordResponse, changePassword] =
    useMutation("/change-password");
  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [, disable] = useMutation("/disable-2fa");

  const enableOtp = async (event: EventFor<"form", "onSubmit">) => {
    event.preventDefault();
    const code = event.currentTarget.code.value;
    await enable({ code });
    api.refreshToken();
  };

  const disableOtp = async (event: EventFor<"form", "onSubmit">) => {
    event.preventDefault();
    await disable(undefined);
    api.refreshToken();
  };

  if (!accessToken) {
    return <Content>Not logged in</Content>;
  }

  const { email, totpEnabled } = accessToken;

  return (
    <Content>
      <Form>
        <LabelContainer htmlFor="email" label="E-mail">
          <TextInput
            id="email"
            name="email"
            type="email"
            autocomplete="email"
            value={email}
            disabled
          />
        </LabelContainer>
      </Form>
      <Form
        onSubmit={(e: EventFor<"form", "onSubmit">) => {
          e.preventDefault();
          changePassword(passwordValues);
        }}
      >
        <LabelContainer htmlFor="oldPassword" label="Old Password">
          <TextInput
            id="oldPassword"
            name="oldPassword"
            type="password"
            autocomplete="password"
            value={passwordValues?.oldPassword}
            onInput={(event: EventFor<"input", "onInput">) =>
              setPasswordValues((values) => ({
                ...values,
                oldPassword: event.currentTarget.value,
              }))
            }
          />
        </LabelContainer>
        <LabelContainer htmlFor="newPassword" label="New Password">
          <TextInput
            id="newPassword"
            name="newPassword"
            type="password"
            autocomplete="password"
            value={passwordValues?.newPassword}
            onInput={(event: EventFor<"input", "onInput">) =>
              setPasswordValues((values) => ({
                ...values,
                newPassword: event.currentTarget.value,
              }))
            }
          />
        </LabelContainer>
        <LabelContainer
          htmlFor="newPasswordConfirm"
          label="Confirm New Password"
        >
          <TextInput
            id="newPasswordConfirm"
            name="newPasswordConfirm"
            type="password"
            autocomplete="password"
            value={passwordValues?.newPasswordConfirm}
            onInput={(event: EventFor<"input", "onInput">) =>
              setPasswordValues((values) => ({
                ...values,
                newPasswordConfirm: event.currentTarget.value,
              }))
            }
          />
        </LabelContainer>
        <ButtonBar>
          <Button type="submit">Change Password</Button>
        </ButtonBar>
        {changePasswordResponse?.error && (
          <ErrorMessage>{changePasswordResponse.error}</ErrorMessage>
        )}
        {changePasswordResponse?.data?.message && (
          <SuccessMessage>
            {changePasswordResponse?.data?.message}
          </SuccessMessage>
        )}
      </Form>
      {!totpEnabled ? (
        <Form onSubmit={enableOtp}>
          <ApiCall path="/gen-2fa">
            {({ data }) => (
              <>
                <p>
                  For secure access to financial information, enabling
                  two-factor authentication on your account is mandatory. We
                  suggest installing the Google Authenticator application on
                  your mobile device for this purpose. The app is available for
                  download from the{" "}
                  <StyledAnchor
                    href="https://apps.apple.com/es/app/google-authenticator/id388497605"
                    rel="noopener nofollow"
                  >
                    Apple App Store for iPhone users
                  </StyledAnchor>{" "}
                  or{" "}
                  <StyledAnchor
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    rel="noopener nofollow"
                  >
                    Google Play Store for Android users
                  </StyledAnchor>
                  . Please scan the provided QR code to set up and generate your
                  two-factor authentication codes. After scanning the QR code,
                  input the six-digit code produced by the app in the space
                  below.
                </p>
                {data?.qrcode && (
                  <img
                    width="256"
                    height="256"
                    class="pixelated"
                    src={data.qrcode}
                  />
                )}
                <LabelContainer label="OTP Code">
                  <TextInput
                    id="code"
                    name="code"
                    type="text"
                    autocomplete="one-time-code"
                    inputmode="numeric"
                    pattern="\d{6}"
                    placeholder="6-digit code"
                    required
                  />
                </LabelContainer>
                {otpResponse.state !== "idle" && !otpResponse.data?.success && (
                  <ErrorMessage>
                    The code you entered is incorrect. Please try again.
                  </ErrorMessage>
                )}
                <ButtonBar>
                  <Button type="submit">Enable 2FA</Button>
                </ButtonBar>
              </>
            )}
          </ApiCall>
        </Form>
      ) : (
        <Form onSubmit={disableOtp}>
          <div class="border text-left bg-gray-100 border-gray-300 px-4 py-2">
            <b>2FA is enabled.</b> To generate a new code, disable 2FA and
            enable it again.
          </div>
          <ButtonBar>
            <Button type="submit">Disable 2FA</Button>
          </ButtonBar>
        </Form>
      )}
    </Content>
  );
};
