import { Route, Switch } from "wouter-preact";
import { Account } from "./account";
import { AddMember } from "./add-member";
import { Authenticated } from "./authenticated";
import { Customers } from "./customers";
import { Members } from "./members";
import { Sidebar } from "./sidebar";
import { Template } from "./template";

export const Entry = () => (
  <Authenticated>
    <Template>
      <Sidebar>
        <Switch>
          <Route path="/">
            <Customers />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/members">
            <Members />
          </Route>
          <Route path="/add-member">
            <AddMember />
          </Route>
        </Switch>
      </Sidebar>
    </Template>
  </Authenticated>
);
