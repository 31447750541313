import { useState } from "preact/hooks";

import { api } from "./api";
import { LabelContainer } from "./label-container";
import {
  Button,
  CenterOnScreen,
  Container,
  ErrorMessage,
  Form,
  TextInput,
} from "./styles";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState<null | string>(null);

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    setError(null);

    const res = await api.login(email, password, otp);

    if (res.ok) {
      return;
    }

    switch (res.status) {
      case 401:
        setError("Invalid login");
        break;
      default:
        setError("An unknown error occurred");
        break;
    }
  };

  return (
    <CenterOnScreen>
      <Container>
        <Form onSubmit={onSubmit}>
          <LabelContainer htmlFor="email" label="Email Address">
            <TextInput
              id="email"
              name="email"
              type="email"
              placeholder="user@example.com"
              autocomplete="email"
              value={email}
              onInput={(event: any) => setEmail(event.currentTarget.value)}
              required
            />
          </LabelContainer>
          <LabelContainer htmlFor="password" label="Password">
            <TextInput
              id="password"
              name="password"
              type="password"
              value={password}
              onInput={(event: any) => setPassword(event.currentTarget.value)}
              autocomplete="current-password"
              required
            />
          </LabelContainer>
          <LabelContainer
            htmlFor="otp"
            label="Two-factor authentication code (if enabled)"
          >
            <TextInput
              id="otp"
              name="otp"
              autocomplete="one-time-code"
              inputmode="numeric"
              type="text"
              pattern="\d{6}"
              placeholder="6-digit code"
              value={otp}
              onInput={(event: any) => setOtp(event.currentTarget.value)}
            />
          </LabelContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div class="flex items-center justify-between">
            <Button type="submit">Sign in</Button>
          </div>
        </Form>
      </Container>
    </CenterOnScreen>
  );
};
