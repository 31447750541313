import { FunctionComponent } from "preact";

import { DataTable } from "./data-table";
import { Content, Green, Orange } from "./styles";
import { useMutation } from "./api";

// for some reason the typescript definitions for the date object are missing
function formatDate(date: Record<string, never>) {
  return new Date(date as unknown as string).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export const Members: FunctionComponent = () => {
  const [, revoke] = useMutation("/revoke-member");
  return (
    <Content>
      <DataTable
        path="/list-members"
        placeholderTitle="No members"
        rowKey="id"
        columns={[
          { name: "Email", accessor: (row) => row.email },
          { name: "Admin", accessor: (row) => (row.admin ? "Yes" : "No") },
          {
            name: "2FA",
            accessor: (row) =>
              row.totpEnabled ? <Green>Yes</Green> : <Orange>No</Orange>,
          },
          {
            name: "Created",
            accessor: (row) => formatDate(row.createdAt),
          },
          {
            name: "Last Login",
            accessor: (row) =>
              row.lastLoginAt ? formatDate(row.lastLoginAt) : "Never",
          },
        ]}
        removeLabel="Revoke"
        removeDisabled={(row) => row.admin}
        onRemove={(row) =>
          revoke({
            id: row.id,
          })
        }
        buttons={[{ name: "Add Member", to: "/add-member" }]}
      />
    </Content>
  );
};
