import { FunctionComponent } from "preact";

import { DataTable } from "./data-table";
import { Content } from "./styles";
import { useCallback } from "preact/hooks";

export const Customers: FunctionComponent = () => {
  const downloadTableAsCSV = useCallback(() => {
    const csv = [
      ["Customer ID", "GGR", "Deposits", "Withdrawals", "Currency"].join(","),
    ];
    const rows = document.querySelectorAll("tr");
    rows.forEach((row) => {
      const cells = row.querySelectorAll("td");
      const data = Array.from(cells).map((cell) => cell.textContent);
      csv.push(data.join(","));
    });
    const csvString = csv.join("\n");
    const a = document.createElement("a");
    a.href = URL.createObjectURL(
      new Blob([csvString], { type: "text/csv;charset=utf-8" })
    );
    a.download = "customer-stats.csv";
    a.click();
  }, []);

  return (
    <Content>
      <DataTable
        path="/customer-stats"
        rowKey="customerId"
        facets={{
          date: { name: "Date", max: 1 },
          currency: { name: "Currency" },
        }}
        columns={[
          { name: "Customer ID", accessor: (row) => row.customerId },
          { name: "GGR", accessor: (row) => row.ggr.toFixed(2) },
          { name: "Deposits", accessor: (row) => row.deposits.toFixed(2) },
          {
            name: "Withdrawals",
            accessor: (row) => row.withdrawals.toFixed(2),
          },
          { name: "Currency", accessor: (row) => row.currency },
        ]}
        search
        placeholderTitle="No Customers"
        placeholder="No customer statistics found for the given time range"
        buttons={[
          {
            name: "Download CSV",
            onClick: downloadTableAsCSV,
          },
        ]}
      />
    </Content>
  );
};
