type Value = string | number | boolean;
type ValueArray = Value[];

export function qs(params: Record<string, Value | Value[] | null | undefined>) {
  const encode = (x: Value) => {
    return encodeURIComponent(x);
  };
  if (Object.keys(params).length === 0) return "";
  const parts: string[] = [];
  function append(key: string, value: string) {
    if (value) parts.push(key + "=" + value);
  }
  for (const key in params) {
    const value = params[key];
    if (Array.isArray(value)) {
      append(key, value.map(encode).join(","));
    } else if (value) {
      append(key, encode(value));
    }
  }
  return "?" + parts.join("&");
}
