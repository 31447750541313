import type { ParamsOption } from "openapi-fetch";
import type {
  FilterKeys,
  OperationRequestBodyContent,
  PathsWithMethod,
} from "openapi-typescript-helpers";
import {
  StateUpdater,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "preact/hooks";

import { WriteAccessContext } from "./authorization";
import { Loading } from "./loading";
import { paths } from "./server";
import { ErrorMessage } from "./styles";
import { qs } from "./qs";

export enum AuthState {
  Authenticated,
  LoggedOut,
  Unknown,
}

const AUTHENTICATION_STATE_CHANGE_EVENT = "authStateChange";
const ACCESS_TOKEN_CHANGE_EVENT = "accessTokenChange";
export const ACCESS_TOKEN_NAME = "_at";
export const REFRESH_TOKEN_NAME = "_rt";

const removeToken = (name: string) => localStorage.removeItem(name);
const storeToken = (name: string, token: string) => {
  localStorage.setItem(name, token);
  window.dispatchEvent(
    new CustomEvent(ACCESS_TOKEN_CHANGE_EVENT, { detail: token })
  );
};
export const getToken = (name: string) => localStorage.getItem(name);

export class Api {
  public authState: AuthState = AuthState.Unknown;

  constructor(private apiBase: string) {
    this.changeAuthState(AuthState.Unknown);
    if (this.timeUntilExpiry() > 0) {
      this.changeAuthState(AuthState.Authenticated);
    }
    this.startRefreshTimer();
  }

  getEndpoint(path: string) {
    return `${this.apiBase}${path}`;
  }

  private changeAuthState(state: AuthState) {
    this.authState = state;
    window.dispatchEvent(
      new CustomEvent(AUTHENTICATION_STATE_CHANGE_EVENT, { detail: state })
    );
  }

  logout() {
    removeToken(ACCESS_TOKEN_NAME);
    removeToken(REFRESH_TOKEN_NAME);
    this.changeAuthState(AuthState.LoggedOut);
  }

  private timeUntilExpiry() {
    const accessToken = getToken(ACCESS_TOKEN_NAME);
    if (!accessToken) {
      return 0;
    }
    const { exp } = JSON.parse(atob(accessToken.split(".")[1]));
    return exp * 1000 - Date.now();
  }

  private startRefreshTimer() {
    const exp = this.timeUntilExpiry();
    const timeout = exp - 60 * 1000;
    setTimeout(() => this.refreshToken(), timeout);
  }

  async refreshToken() {
    const refreshToken = getToken(REFRESH_TOKEN_NAME);
    if (refreshToken) {
      const response = await fetch(this.getEndpoint(`/refresh-access-token`), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken }),
      });

      if (response.status !== 200) {
        this.logout();
        return;
      }

      const json = await response.json();
      this.storeTokens(json.accessToken, json.refreshToken);
    } else {
      this.logout();
    }
  }

  private storeTokens(accessToken: string, refreshToken: string) {
    this.changeAuthState(AuthState.Authenticated);
    storeToken(ACCESS_TOKEN_NAME, accessToken);
    storeToken(REFRESH_TOKEN_NAME, refreshToken);
    this.startRefreshTimer();
  }

  async login(
    email: string,
    password: string,
    otpCode?: string
  ): Promise<{ ok: boolean; status: number }> {
    const response = await fetch(this.getEndpoint(`/login-to-account`), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, otpCode }),
    });

    if (response.ok) {
      const { accessToken, refreshToken } = await response.json();
      this.storeTokens(accessToken, refreshToken);
    }

    return {
      ok: response.ok,
      status: response.status,
    };
  }

  async isAuthenticated() {
    if (this.authState === AuthState.LoggedOut) {
      return false;
    }

    if (this.authState === AuthState.Authenticated) {
      return true;
    }
  }
}

export function useAuth() {
  const [authState, setAuthState] = useState<AuthState>(api.authState);

  useLayoutEffect(() => {
    function onAuthStateChange(event: CustomEvent<AuthState>) {
      setAuthState(event.detail);
    }
    window.addEventListener(
      AUTHENTICATION_STATE_CHANGE_EVENT,
      onAuthStateChange as EventListener
    );
    return () =>
      window.removeEventListener(
        AUTHENTICATION_STATE_CHANGE_EVENT,
        onAuthStateChange as EventListener
      );
  }, []);

  return authState;
}

export function useAccessToken() {
  function unwrap(token: string | null) {
    if (!token) {
      return null;
    }
    return JSON.parse(atob(token.split(".")[1]));
  }
  const [accessToken, setAccessToken] = useState(
    unwrap(getToken(ACCESS_TOKEN_NAME))
  );

  useLayoutEffect(() => {
    function onAccessTokenChange() {
      setAccessToken(unwrap(getToken(ACCESS_TOKEN_NAME)));
    }
    window.addEventListener(
      ACCESS_TOKEN_CHANGE_EVENT,
      onAccessTokenChange as EventListener
    );
    return () =>
      window.removeEventListener(
        ACCESS_TOKEN_CHANGE_EVENT,
        onAccessTokenChange as EventListener
      );
  }, []);

  return accessToken;
}

type ApiSuccess<T> = {
  error: undefined;
  state: "success";
  data: T;
  canWrite: boolean;
};

type ApiIdle = {
  error: undefined;
  state: "idle";
  data: undefined;
  canWrite: boolean;
};

type ApiLoading<T> = {
  error: undefined;
  state: "loading";
  data: T | undefined;
  canWrite: boolean;
};

type ApiError = {
  error: string;
  state: "error";
  data: undefined;
  canWrite: boolean;
};

type ApiData<T> = ApiIdle | ApiLoading<T> | ApiError | ApiSuccess<T>;

async function performFetch<T, U extends object>(
  path: string,
  data: T | undefined,
  setData: StateUpdater<ApiData<T>>,
  signal: AbortSignal,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  sticky?: boolean,
  body?: U
) {
  try {
    const stickyData = sticky ? data : undefined;
    setData({
      data: stickyData,
      error: undefined,
      state: "loading",
      canWrite: false,
    });
    const response = await fetch(api.getEndpoint(path), {
      signal,
      body: body ? JSON.stringify(body) : undefined,
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken(ACCESS_TOKEN_NAME)}`,
      },
    });
    if (signal.aborted) {
      return;
    }
    if (response.status === 200) {
      const data = await response.json();
      setData({
        error: undefined,
        state: "success",
        data: data.data,
        canWrite: data.access === "write",
      });
    } else {
      const data = await response.text();
      setData({
        state: "error",
        error: data,
        data: undefined,
        canWrite: false,
      });
    }
  } catch (e) {
    if (signal.aborted) {
      return;
    }
    setData({
      state: "error",
      error: String(e),
      data: undefined,
      canWrite: false,
    });
  }
}

export function useApi<T>(path: string, sticky = false) {
  const [data, setData] = useState<ApiData<T>>({
    state: "loading",
    error: undefined,
    data: undefined,
    canWrite: false,
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    performFetch(path, data.data, setData, signal, "GET", sticky);

    return () => {
      controller.abort();
    };
  }, [path]);

  return data;
}

type ApiProps<P, T> = {
  path: P;
  children: ({
    data,
    canWrite,
  }: {
    data: T | undefined;
    canWrite: boolean;
  }) => any;
};

export type QueryOptions<T extends { query: any }> = T["query"] extends never
  ? {
      query?: T["query"];
    }
  : {
      query: T["query"];
    };

function Wrapped<Paths extends { [path: string]: any }>() {
  function ApiCall<
    P extends PathsWithMethod<Paths, "get">,
    Get = Paths[P] extends { get: infer G } ? G : never,
    Response = Get extends {
      responses: {
        200: { content: { "application/json": { data: infer U } } };
      };
    }
      ? U
      : never
  >({
    path,
    children,
    query,
    passthrough,
    sticky = false,
  }: ApiProps<P, Response> & {
    passthrough?: boolean;
    sticky?: boolean;
  } & QueryOptions<{
      query: FilterKeys<ParamsOption<Get>["params"], "query">;
    }>) {
    let p = path as string;
    if (typeof query === "object") {
      p = `${String(path)}${qs(query)}`;
    }
    const { data, state, error, canWrite } = useApi<Response>(p, sticky);

    if (state === "error") {
      return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (!passthrough) {
      if (state === "loading") {
        return <Loading />;
      }

      if (!data) {
        return null;
      }
    }

    return (
      <WriteAccessContext.Provider value={canWrite}>
        {children({ data, canWrite })}
      </WriteAccessContext.Provider>
    );
  }

  function useMutation<
    P extends PathsWithMethod<Paths, "post">,
    Post = Paths[P] extends { post: infer G } ? G : never,
    Response = Post extends {
      responses: {
        200: { content: { "application/json": { data: infer U } } };
      };
    }
      ? U
      : never
  >(path: P) {
    const [data, setData] = useState<ApiData<Response>>({
      state: "idle",
      error: undefined,
      data: undefined,
      canWrite: true,
    });

    const controller = new AbortController();
    const signal = controller.signal;

    const mutate = useCallback(
      (body: OperationRequestBodyContent<Post>) => {
        return performFetch(
          path as string,
          data.data,
          setData,
          signal,
          "POST",
          false,
          body
        );
      },
      [path, setData, signal]
    );

    const reset = useCallback(() => {
      setData({
        state: "idle",
        error: undefined,
        data: undefined,
        canWrite: true,
      });
    }, [setData]);

    return [data, mutate, { reset }] as const;
  }

  return {
    ApiCall,
    useMutation,
  };
}

export const ApiCall = Wrapped<paths>().ApiCall;
export const useMutation = Wrapped<paths>().useMutation;

export const api = new Api(process.env.API_BASE!);
