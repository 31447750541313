import { ComponentChildren, FunctionalComponent } from "preact";
import { useState } from "preact/hooks";
import { useRoute } from "wouter-preact";

import { StyledLink } from "./styles";
import { api, useAccessToken } from "./api";

const hamburger = new URL("./icons/hamburger.svg", import.meta.url).toString();

const sidebarLinkClass =
  "font-semibold leading-6 h-10 hover:border-r-2 border-green-500 items-center flex cursor-pointer";

const SidebarLink: FunctionalComponent<{
  to: string;
  onClick?: () => void;
  children: ComponentChildren;
}> = ({ children, to, ...props }) => {
  const [isActive] = useRoute(to);
  return (
    <StyledLink
      to={to}
      {...props}
      class={sidebarLinkClass + " " + (isActive ? "border-r-2" : "")}
    >
      {children}
    </StyledLink>
  );
};

export const Sidebar: FunctionalComponent = ({ children }) => {
  const [hidden, setHidden] = useState(true);
  const accessToken = useAccessToken();
  return (
    <div class="flex h-full flex-col sm:flex-row">
      <div
        class="fixed top-0 left-0 z-20 h-14 w-14 cursor-pointer sm:hidden"
        onClick={() => setHidden(!hidden)}
      >
        <img src={hamburger} class="h-6 w-6 m-4" alt="Open sidebar" />
      </div>
      <div
        class={`h-full p-6 pr-0 border-b border-gray-300 sm:border-r sm:fixed sm:block sm:w-64 ${
          hidden && "hidden"
        }`}
      >
        <SidebarLink to={`/`}>Customers</SidebarLink>
        {accessToken.admin && <SidebarLink to="/members">Members</SidebarLink>}
        <SidebarLink to={`/account`}>Account</SidebarLink>
        <span class={sidebarLinkClass} onClick={() => api.logout()}>
          Sign Out
        </span>
      </div>
      <section class="sm:ml-64 w-full">{children}</section>
    </div>
  );
};
